var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stepper" },
    _vm._l(_vm.steps, function(s, i) {
      return _c("span", {
        key: i,
        staticClass: "step",
        class: { active: i == _vm.step - 1 }
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }