const StepMixin = {
  data() {
    return {
      validated: false
    }
  },
  methods: {
    getForm() {
      return this.$refs.form
    },
    checkValidity() {
      this.validated = true

      return this.$refs.form.checkValidity()
    }
  }
}

export default StepMixin
