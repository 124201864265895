var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "image-picker position-relative" },
    [
      _c(
        "file-upload",
        {
          ref: "fuPhotos",
          staticClass: "btn d-flex justify-content-center",
          class: !_vm.target.url ? "btn-dark add" : "btn-light modify",
          attrs: {
            "input-id": _vm.id,
            "post-action": "/post.method",
            "put-action": "/put.method"
          },
          on: { "input-file": _vm.inputFile, "input-filter": _vm.inputFilter },
          model: {
            value: _vm.photos,
            callback: function($$v) {
              _vm.photos = $$v
            },
            expression: "photos"
          }
        },
        [
          _c("span", { staticClass: "caption align-self-center" }, [
            _vm._v(_vm._s(_vm.caption))
          ])
        ]
      ),
      _vm.showPicture
        ? _c("img", { staticClass: "preview", attrs: { src: _vm.target.url } })
        : _vm._e(),
      _vm.showFileIcon
        ? _c("fa-icon", { staticClass: "preview", attrs: { icon: _vm.icon } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }