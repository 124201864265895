<template>
  <div class="image-picker position-relative">
    <file-upload
      ref="fuPhotos"
      :input-id="id"
      post-action="/post.method"
      put-action="/put.method"
      class="btn d-flex justify-content-center"
      :class="!target.url ? 'btn-dark add' : 'btn-light modify'"
      v-model="photos"
      @input-file="inputFile"
      @input-filter="inputFilter"
    >
      <span class="caption align-self-center">{{ caption }}</span>
    </file-upload>
    <img v-if="showPicture" :src="target.url" class="preview" />
    <fa-icon v-if="showFileIcon" :icon="icon" class="preview" />
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component'

export default {
  name: 'image-picker',
  components: { FileUpload },
  props: {
    id: { type: String, default: 'fuPhotos' },
    target: { type: Object, required: true },
    caption: { type: String, default: 'AJOUTER UNE PHOTO' },
    icon: { type: String, default: 'file' }
  },
  model: {
    prop: 'target'
  },
  data() {
    return {
      photos: []
    }
  },
  computed: {
    showPicture() {
      const { url, type } = this.target

      return !!url && type.startsWith('image/')
    },
    showFileIcon() {
      const { url, type } = this.target

      return !!url && !type.startsWith('image/')
    }
  },
  methods: {
    inputFile(newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get response data
        console.log('response', newFile.response)
        if (newFile.xhr) {
          // Get the response status code
          console.log('status', newFile.xhr.status)
        }
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|gif|png|webp|pdf)$/i.test(newFile.name)) {
          this.$refs.alertFileUpload.showModal()

          return prevent()
        }
      }

      // Create a blob field
      newFile.blob = ''
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
      }

      this.target.file = newFile.file
      this.target.type = newFile.type
      this.target.url = newFile.blob
    }
  }
}
</script>

<style lang="scss" scoped>
#app .image-picker {
  .btn {
    line-height: 24px;

    &.add {
      width: 168px;
      height: 82px;
    }

    &.modify {
      width: 50px;
      height: 50px;
      position: absolute;
      right: 8px;
      bottom: 8px;

      .caption {
        content: url('../../assets/svg/icon-pen.svg');
      }
    }
  }

  img,
  svg {
    &.preview {
      width: 333px;
      height: 176px;
      border-radius: 0.5rem;
      margin: 0;
      object-fit: cover;
    }
  }

  svg.preview {
    padding: 20px;
    background: rgba(0, 0, 0, 0.05);
  }
}
</style>
