const ProfileRegistrationMixin = {
  computed: {
    currentStep() {
      return this.step >= 2 && this.step <= this.steps.length
        ? this.$refs[`step${this.step}`]
        : undefined
    },
    currentCaption() {
      return this.steps[this.step - 1].caption
    },
    currentInfo() {
      return this.steps[this.step - 1].info
    },
    lastStep() {
      return this.step == this.steps.length
    }
  },
  methods: {
    isCurrentFormValid() {
      return (
        !!this.currentStep &&
        this.currentStep.checkValidity() &&
        this.checkStepRules()
      )
    }
  }
}

export default ProfileRegistrationMixin
