<template>
  <div class="stepper">
    <span
      v-for="(s, i) in steps"
      :key="i"
      class="step"
      :class="{ active: i == step - 1 }"
    ></span>
  </div>
</template>
<script>
export default {
  name: 'stepper',
  props: {
    steps: { type: Number, required: true },
    step: { type: Number, required: true }
  }
}
</script>
<style lang="scss">
.stepper {
  .step {
    border-radius: 0.5rem;
    // background-color: #f7f7f7;
    background-color: #fff;
    display: inline-block;
    width: 25px;
    height: 5px;
    margin-right: 2px;

    &.active {
      background-color: #000000;
    }
  }
}
</style>
